
.people-icon {
  color: white;
}

.section-width-users-1 {
  min-width: 7%;
  max-width: 7%;
  @media (max-width: 575px) {
    min-width: auto;
    max-width: 100%;
    margin: 0.5rem;
  }
}

.section-width-users-2 {
  width: 25%;
  @media (max-width: 575px) {
    width: auto;
    text-align: center;
    margin: 0.5rem;
  }
}

.section-width-users-3 {
  width: 30%;
  @media (max-width: 575px) {
    width: auto;
    margin: 0.5rem;
  }
}

.section-width-users-4 {
  width: 20%;
  @media (max-width: 575px) {
    width: auto;
    margin: 0.5rem;
  }
}

.section-width-users-5 {
  width: 18%;
  margin-right: 1rem;
  @media (max-width: 575px) {
    width: auto;
    margin: 0.5rem;
  }
}

.edit-user-container {
  width: 40rem;
  min-height: 20rem;
  background: white;
  border-radius: 6px;
  border: 2px solid black;
  background-color: #24242c;

  @media (max-width: 768px) {
      width: 100%;
      height: 100%;
  }
}

.edit-user-header {
  background-color: #20202a;
  border-bottom: 7px solid #35343a;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-phone-input {
width: 95%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; 
}

.users-container {
height: 72%;
}
