.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(11, 11, 23);
    background: linear-gradient(310deg,
            rgba(11, 11, 23, 1) 45%,
            rgba(11, 11, 23, 0.9637758062126931) 96%);
    height: 100%;

    img {
        max-height: 4em;
    }
}

.login-border {
    background: rgb(46, 46, 54);
    background: linear-gradient(310deg,
            rgba(46, 46, 54, 1) 0%,
            rgba(31, 31, 40, 1) 100%);
    width: 25rem;
    border-radius: 20px;

    @media (max-width: 576px) {
        width: 100%;
        height: 100%;
        border-radius: 0;
        border: none;
    }
}

.login-title {
    font-size: 2rem;
}

.login-input {
    height: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: white;
    border-radius: 0;
    background-color: #2e2e36;
    border: none;
    border-bottom: 1px solid #fff;

    &:hover {
        border-radius: 4px 4px 0 0;
        background-color: #36363d;
    }

    &:focus {
        border-bottom: 3px solid #51fffe;
    }
}

.sign-in-button {
    height: 3.5rem;
}