div.rdw-editor-toolbar {
    background-color: #24242c;
    color: white;
}

div.rdw-editor-toolbar {
    border: none;
    border-bottom: solid 2px #44454a;
}

div.DraftEditor-root {
    padding-left: 0.5rem;
    height: 15rem;
    font-size: 1.2rem;
    background-color: #24242c;
    overflow-y: auto;
}

// here we can edit de textInput area in the editor

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    height: 100%;
}

div.rdw-option-wrapper {
    background-color: #24242c;
    color: white;
    border: none;

    img {
        color: white;
        fill: white;
    }
}

// for styling the botons in the toolbar
ul.rdw-dropdown-optionwrapper {
    border: 1px solid #44454a;
    color: white;
    overflow: auto;
}

li.rdw-dropdownoption-default {
    color: white;
    background-color: #24242c;
    border: none;
}

a.rdw-dropdown-selectedtext {
    text-decoration: none;
    color: white;
    background-color: #24242c;
}

div.rdw-dropdown-wrapper {
    background-color: #24242c;
    border: 2px solid black;
    border-radius: 5px;
    color: white;
}

div.public-DraftStyleDefault-block,
div.public-DraftStyleDefault-ltr {
    margin: 0;
    padding: 0;
}

.text-editor-container {
    border: 2px solid #44454a;
    border-radius: 5px;
    padding: 5px;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.new-template-divider {
    border-bottom: 2px solid #44454a;
}

.toolbar-style-text-editor {
    border-bottom: 3px solid #44454a;
    margin-right: -5px;
    margin-left: -5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
}

.style-button {
    width: 2.3rem;
    height: 2.3rem;
}

.block-style-selector {
    height: 2.3rem;
}

.size-selector-label {
    font-size: 0.7rem;
    margin-top: 0.25rem;
}