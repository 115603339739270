.divider {
  border-top: 1px solid rgba(143, 143, 168, 0.53);
}

.user-avatar {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-opacity {
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 1;
    transition: opacity 300ms ease;
  }
}

.noselect-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.user-avatar-no-src {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-down-menu {
  z-index: 99;
  position: absolute;
  height: auto;
  width: auto;
  top: 3rem;
  right: 1rem;
  border-radius: 4px;
  background-color: #36373c;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.4);
  padding: 0.3rem;
}

.drop-down-background {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
}

.drop-down-menu-buttons {
  &:hover {
    background-color: #404144;
  }
  padding-right: 1rem;
}

.custom-button {
  background-color: #5b99e3;
  border: none;
  height: 2.4rem;
}

.page-container {
  background-color: #24242c;
  margin: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  margin-bottom: 0.5rem;
}

.menu-header {
  border: 2px solid rgba(143, 143, 168, 0.53);
  background-color: #2e2e36;
  border-bottom: 3px solid rgba(189, 189, 221, 0.5);
}

.custom-modal-background {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-control:focus{
  color: #51fffe!important;
  background-color: #38373d!important;
  outline: none!important;
  box-shadow: none!important;
}

.input-select-custom {
  background-color: #38373d;
  outline: none;
  border-radius: 4px;
  border: 1.5px solid #505050;
  margin: 0;
  padding: 0;
  padding-left: 0.8rem;
  color: #51fffe;
  font-size: 1rem;
  height: 2.2rem;
}
.input-custom {
  background-color: #38373d;
  outline: none;
  border-radius: 4px;
  border: 1.5px solid #505050;
  margin: 0;
  padding: 0;
  padding-left: 0.8rem;
  color: #51fffe;
  font-size: 1rem;
  height: 2.2rem;
}
.custom-modal-children {
  z-index: 2001;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.icon-button-animated {
  color: white;
  cursor: pointer;
  transition: all 0.05s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}

.icon-button-spinner {
  height: 1.5rem;
  width: 1.5rem;
}

.text-area-custom {
  // background-color: red;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  min-height: 10rem;
  max-height: 10rem;
  resize: none;
  color: white;
}
