.new-organization-container {
    width: 40rem;
    min-height: 20rem;
    background: white;
    border-radius: 6px;
    border: 2px solid black;
    background-color: #24242c;

    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
}

.new-organization-header {
    background-color: #20202a;
    border-bottom: 7px solid #35343a;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
