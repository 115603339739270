.buttons-messages-screen {
    width: 6rem;
    height: 3.5rem;
}

.messages-header-container {
    background-color: #20202a;
    border-radius: 3px 3px 0px 0px;
    height: 4.5rem;
    border-bottom: 3px solid #17171f;
}

.messages-icon {
    color: white;
}

.pre-stored-messages-container {
    min-height: 32rem;
}

.recipients-container {
    min-height: 32rem;

    @media (min-width: 768px) {
        width: 50%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
}

.message-title {
    width: 50%;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.select-all-spinner {
    width: 1.2rem;
    height: 1.2rem;
}

//this style modifies the  CAccordionHeader component
.accordion-button {
    background-color: #24242c;
    color: white;
    padding: 1rem;
    padding-left: 0.5rem;
}

.accordion-button:not(.collapsed) {
    background-color: #24242c;
    color: white;
}

.accordion-body {
    background-color: #24242c;
    color: white;
}

.accordion-item {
    border: none;
}

.accordion-header {
    background-color: #24242c;
}