.navBarShadow {
    -webkit-box-shadow: 0px 3px 2px -0.5px #000000;
    box-shadow: 0px 1.5px 2px -0.5px #000000;
}

.navbar {
    width: 100%;
}

.icon-white {
    color: white;
    cursor: pointer;
}

.icon-bell {
    margin-top: 0.5rem;
}

.alert-button {
    color: #51fffe;
}

.sidebar-button-nav {
    &:active {
        background-color: #3b3b47;
        border-radius: 3px;
    }
}

.user-menu {
    height: 2rem;
    width: 2rem;
    background-color: red;
    position: absolute;
}

.user-utils {
    margin: auto;
}

.custom-header {
    position: sticky;
    top: 0;
    z-index: 1029;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: 4rem;
}