.bg-dark1 {
    background-color: #2c2d32;
}

.bg-dark2 {
    background-color: #24242c;
}

.bg-dark3 {
    background-color: #20202a;
}

.bg-dark4 {
    background-color: #36373c;
}

.bg-main {
    background-color: #0b0b17;
}

.text-color {
    color: #51fffe;
}

.text-color2 {
    color: #b6fbfe;
}

.default-layout {
    display: flex;
    overflow-y: hidden;
    height: 100%;
}

.main-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: none;
    flex: 1;
}

p {
    padding: 0;
    margin: 0;
}

body>#root>div {
    height: 100vh;
}

*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #7e7e7e;
}

*::-webkit-scrollbar-thumb {
    background-color: #20202a;
    border-radius: 10px;
    border: 3px solid #35343a;
}

.over-flow-auto {
    overflow-y: auto;
}

.over-flow-hidden {
    overflow-y: hidden;
}

.h-90 {
    height: 90%;
}

.h-80 {
    height: 80%;
}

.spinner {
    text-align: center;
}


.otp-screen input:focus-visible,
.verifi-email input:focus-visible {
    outline: none !important;
}